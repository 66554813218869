<template>
	
	<!-- Layout Footer -->
	<a-layout-footer>
		<a-row>
			<a-col :span="24" :md="24">

				<!-- Footer Copyright Notice -->
				<p class="copyright text-center">
					© 2022, Copyright
					by <a href="https://gescapro.net" target="_blank">GESCAPRO</a>
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->

</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>
